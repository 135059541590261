<template>
  <div>
    <div class="text-center pt-4" v-if="loading">
      <b-spinner label="Cargando..."/>
      Cargando...
    </div>

    <b-alert
        variant="warning"
        v-if="errorMessage"
        show
    >
      <div class="alert-body font-small-2">
        <p>
          <small class="mr-50">
            <span class="font-weight-bold">{{ errorMessage }}</span>
          </small>
        </p>
      </div>
    </b-alert>

    <b-row>
      <b-col>
        <b-form @submit.prevent="save(item)">
          <!--SubTitle-->
          <b-row>
            <b-col>
              <b-form-group
                  label="Locación"
              />
            </b-col>
          </b-row>
          <!--End SubTitle-->
          <b-row>
            <b-col cols="6">
              <label>
                Fecha
              </label>
              <b-form-group>
                <b-form-datepicker
                    id="dateE-datepicker"
                    v-model="selectedSince"
                    class="mb-1"
                    placeholder="Fecha"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="es"
                    @input="formatDateSince(selectedSince + 'T23:59:59.999Z')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Semana Epidemiológico" label-for="weekE">
                <b-form-input id="weekE" type="text" v-model="item.week"></b-form-input>
              </b-form-group>
            </b-col>            
            <b-col cols="6">
              <b-form-group
                  label="Región"
                  label-for="region"
              >
                <b-form-select
                    v-model="region"
                    :options="locations.regiones"
                    options-field="none"
                    id="region"
                    text-field="region"
                    @change="getDistritos()"
                >
                  <template #first>
                    <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  label="Distrito"
                  label-for="district"
              >
                <b-form-select
                    v-model="district"
                    :options="locations.distritos"
                    options-field="none"
                    id="distrito"
                    text-field="distrito"
                    @change="getCorregimientos()"
                >
                  <template #first>
                    <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                  label="Corregimiento"
                  label-for="corregimiento"
              >
                <b-form-select
                    v-model="corregimiento"
                    :options="locations.corregimientos"
                    options-field="none"
                    id="corregimiento"
                    text-field="corregimiento"
                    @change="getLocalidades()"
                >
                  <template #first>
                    <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  label="Localidad"
                  label-for="localidad"
              >
                <b-form-select
                    v-model="localidad"
                    :options="locations.localidades"
                    options-field="none"
                    id="localidad"
                    text-field="localidad"
                >
                  <template #first>
                    <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <!--SubTitle-->
          <b-row>
            <b-col>
              <b-form-group
                  label="Predios"
              />
            </b-col>
          </b-row>
          <!--End SubTitle-->
          <b-row>
            <b-col cols="3">
              <b-form-group
                  label="Inspecionados"
                  label-for="inspecionados"
              >
                <b-form-input
                    id="inspecionados"
                    v-model="item.inspecionados"
                    placeholder=""
                    type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                  label="Nebulizados"
                  label-for="nebulizados"
              >
                <b-form-input
                    id="nebulizados"
                    v-model="item.nebulizados"
                    placeholder=""
                    type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                  label="Rociados"
                  label-for="rociados"
              >
                <b-form-input
                    id="rociados"
                    v-model="item.rociados"
                    placeholder=""
                    type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="3">
              <b-form-group
                  label="Insecticidas (kg) Roc."
                  label-for="inspecionadosKg"
              >
                <b-form-input
                    id="inspecionadosKg"
                    v-model="item.inspecionadosKg"
                    placeholder=""
                    type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                  label="Insecticidas (kg) Neb"
                  label-for="nebulizadosKg"
              >
                <b-form-input
                    id="nebulizadosKg"
                    v-model="item.nebulizadosKg"
                    placeholder=""
                    type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <!--SubTitle-->
          <b-row>
            <b-col>
              <b-form-group
                  label="Personas Infestadas"
              />
            </b-col>
          </b-row>
          <!--End SubTitle-->
          <b-row>
            <b-col cols="3">
              <b-form-group
                  label="Reciente"
                  label-for="infestadasReciente"
              >
                <b-form-input
                    id="infestadasReciente"
                    v-model="item.infestadasReciente"
                    placeholder=""
                    type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                  label="Sospechosos"
                  label-for="sospechososInfestadas"
              >
                <b-form-input
                    id="sospechososInfestadas"
                    v-model="item.sospechososInfestadas"
                    placeholder=""
                    type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                  label="Actuales"
                  label-for="actualesInfestadas"
              >
                <b-form-input
                    id="actualesInfestadas"
                    v-model="item.actualesInfestadas"
                    placeholder=""
                    type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="3">
              <b-form-group
                  label="Tratados Si"
                  label-for="tratadosSi"
              >
                <b-form-input
                    id="tratadosSi"
                    v-model="item.tratadosSi"
                    placeholder=""
                    type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                  label="Tratados No"
                  label-for="tratadosNo"
              >
                <b-form-input
                    id="tratadosNo"
                    v-model="item.tratadosNo"
                    placeholder=""
                    type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="3">
              <b-form-group
                  label="Promoción"
                  label-for="promocion"
              >
                <b-form-input
                    id="promocion"
                    v-model="item.promocion"
                    placeholder=""
                    type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                  label="Hab. Investigados"
                  label-for="habInvestigados"
              >
                <b-form-input
                    id="habInvestigados"
                    v-model="item.habInvestigados"
                    placeholder=""
                    type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <!--SubTitle-->
          <b-row>
            <b-col>
              <b-form-group
                  label="Entomología Aplicada"
              />
            </b-col>
          </b-row>
          <!--End SubTitle-->
          <b-row>
            <b-col cols="3">
              <b-form-group
                  label="Trampa"
                  label-for="trampa"
              >
                <b-form-input
                    id="trampa"
                    v-model="item.trampa"
                    placeholder=""
                    type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                  label="Tipo de Cebo"
                  label-for="ceboTipo"
              >
                <b-form-input
                    id="ceboTipo"
                    v-model="item.ceboTipo"
                    placeholder=""
                    type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                  label="Vector (Especie)"
                  label-for="vectorEspecie"
              >
                <b-form-input
                    id="vectorEspecie"
                    v-model="item.vectorEspecie"
                    placeholder=""
                    type="text"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                  label="Cantidad"
                  label-for="cantidad"
              >
                <b-form-input
                    id="cantidad"
                    v-model="item.cantidad"
                    placeholder=""
                    type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-2">
              <b-alert
                  variant="warning"
                  v-if="errorMessage"
                  show
              >
                <div class="alert-body font-small-2">
                  <p>
                    <small class="mr-50">
                      <span class="font-weight-bold">{{ errorMessage }}</span>
                    </small>
                  </p>
                </div>
              </b-alert>
            </b-col>
            <b-col class="mt-2 mb-4">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
              >
                <span v-if="!loading">Guardar</span>
                <span v-if="loading">Un momento...</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="$router.go(-1)"
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>


    <!--    Toast-->
    <b-toast id="example-toast" variant="primary">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-center mr-1">
          <b-img
              src="/logo.jpeg"
              class="mr-1"
              height="18"
              width="18"
              alt="Icon"
          />
          <strong class="mr-auto">Alerta</strong>
        </div>
      </template>
      <span>La fecha <strong>Desde</strong> debe ser menor a la fecha <strong>Hasta</strong>. </span>
    </b-toast>
  </div>
</template>

<script>
import {
  BAlert, BAvatar, BBadge, BPagination, BForm, BFormGroup, BFormInput, BFormSelect, BRow, BCol,
  BButton, BDropdown, BDropdownItem, BFormDatepicker, BToast, BSpinner, BFormFile, BFormSelectOption
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import environment from "@/environment";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {format} from "date-fns";

export default {
  name: 'DocumentAdd',
  components: {
    BAlert,
    BAvatar,
    BBadge,
    BCardCode,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BToast,
    BSpinner,
    BFormFile,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedSince: null,
      region: '',
      district: '',
      corregimiento: '',
      localidad: '',
      errorMessage: '',
      loading:false,
      locations: {
        regiones: [],
        corregimientos: [],
        distritos: [],
        localidades: []
      },
      item: {}
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async getRegiones() {
      try {
        this.loading = true
        const resp = await this.$http.get(`${environment.uri}stats/regiones`)
        this.locations.regiones = resp.data.map(r => {
          return {...r, value: r}
        })
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async getDistritos() {
      try {
        this.loading = true
        const resp = await this.$http.get(`${environment.uri}stats/distritos?id_region=${this.region.id_region}`)
        this.locations.distritos = resp.data.map(r => {
          return {...r, value: r}
        })
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async getCorregimientos() {
      try {
        this.loading = true
        const resp = await this.$http.get(`${environment.uri}stats/corregimientos?id_region=${this.region.id_region}&codigo_distrito=${this.district.codigo_distrito}`)
        this.locations.corregimientos = resp.data.map(r => {
          return {...r, value: r}
        })
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async getLocalidades() {
      try {
        this.loading = true        
        const resp = await this.$http.get(`${environment.uri}stats/localidades?id_region=${this.region.id_region}&codigo_distrito=${this.district.codigo_distrito}&codigo_corregimiento=${this.corregimiento.codigo_corregimiento}`)
        this.locations.localidades = resp.data.map(r => {
          return {...r, value: r}
        })
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async save() {
      try {
        this.loading = true
        this.errorMessage = ''

        this.item.regionLabel = this.region.region
        this.item.districtLabel = this.district.distrito
        this.item.corregimientoLabel = this.corregimiento.corregimiento
        this.item.localidadLabel = this.localidad.localidad

        this.item.region = this.region.id_region
        this.item.district = this.district.id_distrito
        this.item.corregimiento = this.corregimiento.id_corregimiento
        this.item.localidad = this.localidad.id_localidad

        await this.$http.put(`${environment.uri}stats/leishmaniasi/${this.$route.params.id}`, this.item)

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Guardado`,
            icon: 'CoffeeIcon',
            variant: 'Info',
            text: `Has ingresado exitosamente`,
          },
        })
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
        await this.$router.push('/stats/leishmaniasi/list')
      }
    },
    async load() {
      try {
        this.loading = true
        await this.getRegiones()
        const resp = await this.$http.get(`${environment.uri}stats/leishmaniasi/${this.$route.params.id}`)
        this.item = resp.data
        this.selectedSince = this.item.dateE
        this.region = this.locations.regiones.find(l=> l.id_region === this.item.region).value
        await this.getDistritos()
        this.district = this.locations.distritos.find(l=> l.id_distrito === this.item.district).value
        await this.getCorregimientos()
        this.corregimiento = this.locations.corregimientos.find(l=> l.id_corregimiento === this.item.corregimiento).value
        await this.getLocalidades()
        this.localidad = this.locations.localidades.find(l=> l.id_localidad === this.item.localidad).value

      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async formatDateSince(a) {
      try {
        this.loading = true
        const date = new Date(a);
        const fdate = format(date, 'yyyy-MM-dd')
        this.item.dateE = fdate
        const resp = await this.$http
        .get(`${environment.uri}weeks/${fdate}`)
        this.item.week = resp.data.week
      } catch(error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.pLabel label{
  padding-left: 18px;
}
</style>
